
export const rotas = {
  // Dashboard
  dashboard: 'dashboard',
  painelFiscal: 'painel-fiscal',
  painelFolha: 'painel-folha',
  painelContabil: 'painel-contabil',
  painelGerenciador: 'painel-gerenciador',
  // Root
  relatorios: 'relatorios',
  relatoriosImpressos: 'relatorios-impressos',
  // Listas
  contabeisLista: 'contabeis-lista',
  folhaLista: 'folha-lista',
  fiscalLista: 'fiscal-lista',
  listaContatos: 'lista-contatos',
  // Relatórios em tela
  analiseBalanco: 'analise-balanco',
  balanceteTela: 'balancete',
  dmpl: 'dmpl',
  geradorDemonstracao: 'gerador-demonstracao',
  razaoTela: 'razao',
  // Relatório impresso
  relatorio: 'relatorio',
  rescisao: 'rescisao',
  // Calculo folha
  calculoFolha: 'calculo-folha',
  calculoMensal: 'calculo-mensal',
  primeira13: 'primeira-decimo-terceiro',
  segunda13: 'segunda-decimo-terceiro',
  calculoFerias: 'calculo-ferias',
  calculoRescisao: 'calculo-rescisao',
  eventoProgramado: 'evento-programado',
  adiantamento: 'adiantamento',
  // Contabil
  assinante: 'assinante',
  bloquarPeriodo: 'bloquear-periodo',
  caixaLancamento: 'caixa-lancamento',
  conciliacaoBancaria: 'conciliacao-bancaria',
  conciliacaoBancariaExtratoExcel: 'extrato/excel',
  conciliacaoBancariaExtratoOfx: 'extrato/ofx',
  conciliacaoBancariaHistorico: 'historico-bancario',
  conciliacaoBancariaVinculo: 'vinculo',
  contabilista: 'contabilista',
  depreciacao: 'depreciacao',
  encerramento: 'encerramento',
  fornecedores: 'fornecedores-clientes',
  gruposPatrimonio: 'grupos-patrimonio',
  historico: 'historico',
  implantacaoSaldoInicial: 'implantacao-saldo-inicial',
  trocarConta: 'trocar-conta',
  importacaoSped: 'importacao-sped',
  lancamento: 'lancamento',
  lancamentoPadrao: 'lancamento-padrao',
  patrimonios: 'patrimonios',
  plano: 'plano',
  renumerarLancamento: 'renumerar-lancamento',
  retornosSped: 'retornos-sped',
  spedEcd: 'sped-ecd',
  // Folha
  anuenio: 'anuenio',
  cargos: 'cargos',
  departamentos: 'departamentos',
  dependente: 'dependentes',
  afastamento: 'afastamento',
  valetransporte: 'vale-transporte',

  // eventosEsocial: 'eventos-esocial',
  eventosEsocial2: 'esocial',
  feriados: 'feriados',
  colaboradorPreliminar: 'colaborador-preliminar',
  colaboradorComVinculo: 'colaborador-com-vinculo',
  colaboradorSemVinculo: 'colaborador-sem-vinculo',
  listaColaboradores: 'lista-colaboradores',
  lancamentoVt: 'lancamento-vt',
  lotacoesTributarias: 'lotacoes-tributarias',
  linhaTransporte: 'linha-transporte',
  rubricas: 'rubricas',
  turnos: 'turnos',
  calculoMedias: 'calculo-medias',
  faltas: 'faltas',
  // Fiscal
  anexo: 'anexo',
  cfop: 'cfop',
  faturamentoSimples: 'faturamento-simples',
  faturamentoLucroPresumido: 'faturamento-lucro-presumido',
  entrada: 'entrada',
  saida: 'saida',
  dfeImportacao: 'importacao-dfe',
  definicaoContabil: 'definicao-contabil',
  lancamentoFiscal: 'lancamento-fiscal',
  nfe: 'nfe',
  nfse: 'nfse',
  resultadoBusca: 'resultado-busca',
  produtos: 'produtos',
  servicos: 'servicos',
  importanota: 'digibyte-xml',
  ecacSimples: 'ecac-simples',

  // Compartilhados
  acessoNegado: 'acesso-negado',
  certificadoDigital: 'certificado-digital',
  configuracoes: 'configuracoes-sistema',
  empresa: 'empresa',
  empresas: 'empresa-geral',
  estabelecimento: 'estabelecimento',
  fornecedoresClientes: 'fornecedores-clientes',
  listaContaReferencial: 'lista-conta-referencial',
  listaPlanoModelo: 'lista-plano-modelo',
  municipio: 'municipio',
  notaLancamento: 'nota-lancamento',
  inss: 'tabela-inss',
  irrf: 'tabela-irrf',
  bancos: 'bancos',
  contaCorrentes: 'conta-corrente',
  // Gerenciador
  contasReceber: 'contas-receber',
  eventos: 'eventos',
  honorarios: 'honorarios',
  inadimplentes: 'relatorio-inadimplentes',
  pagamentosRecebidos: 'relatorio-recebidos',
  cobrancaBancaria: 'cobranca-bancaria',
  empresaContabil: 'contabilidade',  // não alterar, usado em lista-empresa.component.ts
  semEscritorio: 'sem-escritorio',
}

export const verbos = {
  incluir: 'incluir',
  alterar: 'alterar',
  excluir: 'excluir',
  restaurar: 'restaurar',
  detalhar: 'detalhar'
}

export const classeErro = 'erro-validacao'

export const modulo = {
  folha: 0,
  adiantamento: 1,
  ferias: 2,
  rescisao: 3,
  primeira13: 4,
  segunda13: 5
}

export const iconesMenu = {
  contabil: 'fa-calculator',
  folha: 'fa-users',
  fiscal: 'fa-file-invoice-dollar',
  gerenciador: 'fa-table'
}
