
<header class="bg-light border-botton border-secondary pt-5 pb-3 px-3" style="flex-shrink: 0;">
  <div class="form-row">
    <div class="col-md-2">

    <a href="javascript:;" routerLink="/" class="brand-logo">
      <img alt="logo" [attr.src]="headerLogo" style="max-width: 150px;" />
    </a>
  </div>

    <div class="col-md-8">
      <!-- <app-data-movimento (click)="carregarEmpresas()"></app-data-movimento> -->
      <!-- <app-esocial-movimento></app-esocial-movimento> -->
      <div class="form-row mx-4" style="display: flex; align-items: center; justify-content: center;">

        <div class="form-group text-center mb-0">
          <div class="btn-group" role="group" *ngFor="let mes of meses; let i = index;">
            <button type="button" class="btn btn-sm btn-custom-hover" [ngClass]="(m === i) ? 'btn-primary' : 'btn-outline-secondary'"
              [(ngModel)]="m" ngDefaultControl (click)="setarDataMovimentoPorMes(i)">{{ mes }}</button>
          </div>
        </div>

        <div class="form-group col-md-2 mb-0">
          <ng-select [(ngModel)]="a" [items]="anos" [virtualScroll]="true" bindValue="id" bindLabel="ano"
            placeholder="ANO" ngDefaultControl (change)="setarDataMovimentoPorAno()"
            [clearable]="false" class="primary-ng-select"></ng-select>
        </div>

      </div>
    </div>

    <div class="form-group col-md-2 d-flex justify-content-end" style="position: relative; top: 2px">
      <button  class="btn btn-sm btn-outline-digibyte"
        (click)="processarESocial()">Processar</button>
      <button  class="btn btn-sm btn-outline-digibyte ml-1" (click)="apagarBase()">Zerar
        Base</button>
    </div>

  </div>
</header>


 <div class="card w-100 mx-auto">

  <div class="card-body d-flex">

    <div style="flex: 0 0 25%;">

      <p-table #dt1 styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="flex" [rowHover]="true"
        selectionMode="single" [(selection)]="selectedEmpresa" [loading]="loadingdt1" [value]="listaEmpresas"
        dataKey="id" [globalFilterFields]="['nome']">

        <ng-template pTemplate="caption">
          <div class="container">
            <div class="row align-items-center">
              <div class="col d-flex">
                <!-- Input -->
                <span class="p-input-icon-right flex-grow-1">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" #filter class="form-control"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Pesquisa"
                  />
                </span>
                <!-- Botão -->
                <button
                  pButton class="btn-sm p-button-outlined p-button-secondary"
                  icon="pi pi-times" (click)="clear(dt1)">
                </button>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <!-- <th class="text-left" style="cursor: pointer;" (click)="clear(dt2)"><i class="pi pi-search mr-2"></i>Empresa</th> -->
            <th pSortableColumn="nome">Empresa <p-sortIcon field="nome"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-listaEmpresas>
          <tr [pSelectableRow]="listaEmpresas">
            <td style="cursor: pointer;" (click)="empresaEscolhida(listaEmpresas.id)">
              <!-- Nome da Empresa -->
              <div>
                <i class="pi pi-check" style="color: green;"></i>
                {{ listaEmpresas.nome}}
              </div>

              <!-- Informações de Status -->
              <div class="status-info">
                <small>Processando</small>
                <span class="badge badge-pill badge-primary ml-1 mr-2">{{ listaEmpresas.processando }}</span>
                <small>Envio Manual</small>
                <span class="badge badge-pill badge-warning ml-1 mr-2">{{ listaEmpresas.pendenteManual }}</span>
                <small>Erros</small>
                <span class="badge badge-pill badge-danger ml-1 mr-2">{{ listaEmpresas.erros }}</span>
              </div>

              <!-- Certificados -->
              <div class="certificates">
                <span class="badge badge-success text-light"
                  *ngIf="!listaEmpresas.certificadoVencido && listaEmpresas.certificadoDigitalId !== null">
                  <small>Certificado OK</small>
                </span>
                <span class="badge badge-danger text-light pointer"
                  *ngIf="listaEmpresas.certificadoDigitalId === null"
                  (click)="navegarParaCertificadoDigital(listaEmpresas.certificadoDigitalId)">
                  <small>Sem Certif. Digital</small>
                </span>
                <span class="badge badge-warning text-light pointer"
                  *ngIf="listaEmpresas.certificadoVencido && listaEmpresas.certificadoDigitalId !== null"
                  (click)="navegarParaCertificadoDigital(listaEmpresas.certificadoDigitalId)">
                  <small>Certif. Digital Vencido</small>
                </span>
              </div>
            </td>
          </tr>

        </ng-template>
      </p-table>
    </div>
    <div style="flex: 1;">
      <p-table #dt2 styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="flex" [rowHover]="true"
        [loading]="loadingdt2" [value]="eventos" dataKey="id"
        [globalFilterFields]="['tipo','descricaoTipo','descricao']">

        <ng-template pTemplate="caption">
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto">
                <!-- Adiciona o texto informativo aqui -->
                <span>{{ selectedEmpresa.nome }}</span>
              </div>
              <div class="col-auto">
                <div class="d-flex justify-content-end">
                  <p-dropdown
                    class="mr-2"
                    [options]="opcoes"
                    [(ngModel)]="opcaoSelecionada"
                    placeholder="Opções"
                    [editable]="false"
                    optionLabel="nome">
                  </p-dropdown>
                  <span class="p-input-icon-right">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" #filter  class="form-control"
                      (input)="dt2.filterGlobal($event.target.value, 'contains')"
                      placeholder="Pesquisa" />
                  </span>
                  <button pButton class="btn-sm p-button-outlined p-button-secondary" icon="pi pi-times"
                    (click)="clear(dt2)"></button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>


        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="cursor: pointer;" (click)="clear(dt2)"><i
                class="pi pi-search mr-2"></i>Descrição</th>
            <th class="text-center">Competência</th>
            <th class="text-center" tooltip="Data e hora de envio" placement="right">Data/Hora</th>

            <th class="text-center">Status</th>
            <!-- <th style="min-width:12rem">
              <div class="flex align-items-center">
                  Status
                  <p-columnFilter field="situacao" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                      <ng-template pTemplate="header">
                          <div class="px-3 pt-3 pb-0">
                              <span class="font-bold">Selecione o status</span>
                          </div>
                      </ng-template>
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect [ngModel]="value" [options]="situacoes"
                          placeholder="Qualquer" (onChange)="filter($event.value)"
                          optionLabel="descricao">
                              <ng-template let-option pTemplate="item">
                                  <div class="inline-block vertical-align-middle">
                                      <span class="ml-1 mt-1">{{ option.descricao }}</span>
                                  </div>
                              </ng-template>
                          </p-multiSelect>
                      </ng-template>
                  </p-columnFilter>
              </div>
            </th> -->

            <th class="text-center" tooltip="Informações enviadas e recebidas" placement="left">XML</th>
            <th class="text-center">Erros</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-eventos>
          <tr [ngClass]="{'row-accessories': eventos.tipo === '1020'}">
            <td class="td-descricao" (click)="selEvento(eventos)">S{{ eventos.tipo }} - {{ eventos.descricaoTipo }}<br>
              <small>{{
                eventos.descricao }}</small>
            </td>
            <td class="text-center"> {{ eventos.dataCompetencia | date:'MM/yyyy' }} </td>
            <td class="text-right"> {{ eventos.dataEnvio | date:'dd/MM/yy' }} <br>
              <small>{{ eventos.dataEnvio | date:'hh:mm' }}</small>
            </td>

            <td class="text-center" style="cursor: pointer;">
              <p-badge
                [value]="getDescricaoPorId(eventos.situacao)"
                [severity]="getBadgeSeverity(eventos.situacao)"
                (click)="abrirEvento(eventos)">
              </p-badge>
            </td>

            <td class="text-center">
              <a *ngIf="eventos.xmlConsulta !== null && eventos.xmlConsulta !== ''" href="{{ eventos.xmlConsulta }}"
                target="_blank">
                <i class="fa-regular fa-eye icon-black mx-1" tooltip="Consulta ao eSocial" placement="top"
                  role="button"></i>
              </a>
              <a *ngIf="eventos.Evento !== null && eventos.xmlEvento !== ''" href="{{ eventos.xmlEvento }}"
                target="_blank">
                <i class="fa-regular fa-file-lines icon-black mx-1" tooltip="Evento" placement="top" role="button"></i>
              </a>
              <a *ngIf="eventos.xmlEnvio !== null && eventos.xmlEnvio !== ''" href="{{ eventos.xmlEnvio }}"
                target="_blank">
                <i class="fa-regular fa-envelope icon-black mx-1" tooltip="Lote enviado" placement="top"
                  role="button"></i>
              </a>
              <i *ngIf="eventos.erroValidacao !== null && eventos.erroValidacao !== ''"
                class="ml-1 fa fa-info-circle fa-1 text-danger mx-2" aria-hidden="true"
                (click)="openErrorModal(eventos.erroValidacao)" role="button"> erro </i>
            </td>

            <td class="text-center td-opcao">
              <div *ngIf="eventos.situacao === 2 || eventos.situacao === 4 || eventos.situacao === 7"
                class="btn-group text-rigth" role="group">
                <a class="btn btn-icon btn-outline-digibyte btn-icon-digibyte opcoesHover text-left">
                  <i class="flaticon-more-v2" id="icon"></i>
                  <div class="buttontip" id="opcoes">
                    <button class="btn btn-outline-digibyte btn-sm goto" (click)="baixarErros()">Baixar
                      erros</button>
                    <button class="btn btn-outline-digibyte btn-sm goto" *ngIf="eventos.id !== null"
                      (click)="baixarErrosLote(eventos.id)">Bx. selecionado</button>
                  </div>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>

  </div>

  <footer class="bg-light py-1 border-top border-secondary">
    <div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
      <span class="text-bold">{{ empresa }}</span>
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">{{ today | date:'yyyy' }} &copy;</span>
        <a href="{{ link }}" target="_blank" class="text-dark-75 text-hover-primary">{{ representante }}</a>
      </div>
    </div>
  </footer>

</div>


<!-- Modal de erro -->
<ng-template #errorModal>
  <div class="modal-header">
    <h4 id="dialog-static-name" class="modal-title">Erro de envio do eSocial</h4>
  </div>
  <div class="modal-body">
    <p>{{ errorMessage }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-digibyte btn-sm" (click)="modalRef?.hide()">Fechar</button>
  </div>
</ng-template>

<!-- https://stackoverflow.com/questions/54218420/table-with-virtual-scroll-and-flex-scroll-not-rendering-rows
https://github.com/primefaces/primeng/issues/9025 -->
